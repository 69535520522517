<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SLY_GEN"
      tooltip-title="SLY_GEN"
      :display-breadcrumb="true"
    >
      <template v-slot:filterItems>
        <h2 v-i18n="result" class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
      </template>
    </TitleWrapper>

    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(salaryGeneratorList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHead" :key="index">
            <span v-i18n="dashboard">
              {{ head }}
            </span>
          </TH>
          <TH>Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(salary, index) in salaryGeneratorList" :key="index">
          <TD>
            <span
              class="text-primary-green cursor-pointer"
              @click="redirectToSalaryTemplate(salary)"
            >
              {{ salary.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD>
            {{ salary.salary_title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ salary.generate_on_day_of_month || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <TableAction
              :idx="index"
              :current-user="salary"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="totalSalaryGeneratorsCount > 10"
      v-show="!isLoading"
      :key="`ForceRender${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(totalSalaryGeneratorsCount, filteredRecordLimit)"
      @filterRecord="_getSalaryGenerator"
    />
    <GenerateSalaryModal v-if="showModal" :edit="editSalaryObject" @update="getNewData" />
    <UiConfirmationComponent
      header="Delete Salary Generator"
      button-title="Delete"
      message="Are you sure you want to delete Salary Generator"
      :modal="deleteModelShow"
      :is-loading="isModalLoading"
      @confirm="deleteSalaryGeneratorById"
      @cancel="toogleDeleteModel"
    />
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import GenerateSalaryModal from '@components/salary-manager/SalaryGeneratorModal.vue'
import TableAction from '@components/TableAction.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    Loader,
    TitleWrapper,
    Pagination,
    GenerateSalaryModal,
    TableAction,
    UiConfirmationComponent: () => import('@src/components/UiElements/UIConfirmationModal.vue'),
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin, scrollMixin],
  data() {
    return {
      forceRender: 0,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      deleteModelShow: false,
      totalSalaryGeneratorsCount: 0,
      tableHead: ['Title', 'Salary Title', 'Generate on day of month'],
      salaryGeneratorList: [],
      noRecord: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      filteredRecordLimit: 10,
      editSalaryObject: null,
      currentSalaryObject: {},
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
      isModalLoading: (state) => state.salary.isModalLoading,
      showModal: (state) => state.salary.showModal,
    }),
  },
  watch: {
    showModal: {
      handler(value) {
        if (!value) this.editSalaryObject = null
      },
    },
  },
  created() {
    this.setRightbar()
    this._getSalaryGenerator()
  },
  methods: {
    isEmpty,
    redirectToSalaryTemplate(salary) {
      this.$router?.push({
        name: 'salary-template',
        params: {
          id: salary.id,
        },
      })
    },

    getNewData() {
      this._getSalaryGenerator()
      this.editSalaryObject = null
      this.forceRender++
    },

    deleteSalaryGeneratorById() {
      this.delSalaryGenerator(this.currentSalaryObject.id)
    },

    async delSalaryGenerator(id) {
      const [res, err] = await this.deleteSalaryGenerator(id)
      this.toogleDeleteModel()
      this.getNewData()
    },

    async _getSalaryGenerator(range) {
      const paginationLimit = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      const [res, err] = await this.getSalaryGenerators()
      this.salaryGeneratorList = res.data.records
      this.totalSalaryGeneratorsCount = res.data.meta.total_records
    },

    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
    },

    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          this.editSalaryObject = currentType
          this.setModal(true)
          break
        case 'Delete':
          this.currentSalaryObject = currentType
          this.toogleDeleteModel()
          break
      }
    },

    setRightbar() {
      this.setRightbarContent({
        header: {
          title: 'Salary',
          buttons: [
            {
              title: 'Add Salary Generator',
              classes: ['primary-button-right'],
              action: { name: 'salary/showModal' },
            },
          ],
        },
      })
    },

    ...mapActions('salary', ['getSalaryGenerators', 'setModal', 'deleteSalaryGenerator']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
