<template>
  <!-- <ValidationObsserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    :prevent-out-side-close="true"
    :modal-show="showModal"
    :modal-width="43"
    :modal-padding-top="true"
    footer-classes="flex justify-end gap-3"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <div>
        <span v-if="edit" v-i18n="dashboard">Edit</span>
        <span v-else v-i18n="dashboard">Add</span>
        <span v-i18n="dashboard">Salary Generator</span>
      </div>
    </template>
    <template v-slot>
      <div class="py-6 px-6">
        <div class="flex flex-col">
          <div class="">
            <InputBox
              v-model="salaryGenerator.title"
              type="text"
              name="Title"
              title="Title"
              label="Title"
              placeholder="Write"
              :class="$style.widthItems"
              class="flex-1 w-full"
              rules="required"
            />
          </div>
          <div class="">
            <InputBox
              v-model="salaryGenerator.salary_title"
              type="text"
              name="salary_title"
              title="Salary Title"
              label="Salary Title"
              placeholder="Salary Title"
              :class="$style.widthItems"
              class="flex-1 w-full"
              rules="required"
            />
          </div>
          <InputBox
            v-model="salaryGenerator.generate_on_day_of_month"
            type="text"
            name="generate_on_day_of_month"
            title="GENERATE_ON_DAY_OF_MONTH"
            label="Generate on day of month"
            placeholder="Generate on day of month"
            :class="$style.widthItems"
            rules="between:1,30|required"
            class="flex-1 w-full"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="dashboard"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        :disabled="isLoading"
        @click="handleClick('cancel')"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click="handleSubmit(saveSalaryGenerator(invalid))"
      >
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else>
          <span v-if="edit" v-i18n="dashboard">Update</span>
          <span v-else v-i18n="dashboard">Save</span>
        </span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { objectDeepCopy } from '@utils/generalUtil'

export default {
  components: {
    Loader,
    InputBox,
    UiModalContainer,
    // ValidationObserver,
  },
  props: {
    edit: { type: Object, default: null },
  },
  emits: ['update'],
  data() {
    return {
      dashboard: 'dashboard',
      title: 'title',
      salaryGenerator: {},
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isModalLoading,
      showModal: (state) => state.salary.showModal,
    }),
  },
  mounted() {
    if (this.edit) {
      this.salaryGenerator = objectDeepCopy(this.edit)
    }
  },

  methods: {
    async saveSalaryGenerator(invalid) {
      /* INVALID */
      if (invalid) return
      let res, err
      if (this.edit) {
        ;[res, err] = await this.updateSalaryGenerator(this.salaryGenerator)
      } else {
        ;[res, err] = await this.createSalaryGenerator(this.salaryGenerator)
      }
      if (res) {
        this.handleClick('close')
        this.$emit('update')
      }
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setModal(true)
          break
        case 'close':
          this.setModal(false)
          break
        case 'cancel':
          this.setModal(false)
          break
        case 'confirm':
          this.setModal(false)
          break
      }
    },

    ...mapActions('salary', ['setModal', 'createSalaryGenerator', 'updateSalaryGenerator']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
